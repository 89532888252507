/*
TSL_EED_MOS (TEEM) Calculator WebApp

Copyright © 2023  Jennifer Li, Hainsworth Shin, Caroline Pinto, Alan Hood

This JavaScript program is free software: you can redistribute it and/or modify it under the terms of the GNU Affero General Public License as published by the Free Software Foundation, version 3 of the License. This JavaScript program 
is distributed WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU Affero General Public License for more details at https://www.gnu.org/licenses/agpl-3.0.html. 
The TSL_EED_MOS (TEEM) Calculator WebApp JavaScript code cannot be included in closed source software under this license.

*/

import "./App.css";
import { read, utils } from "xlsx";
import React from "react";
import template from "./attachments/TEEM_Calculator_Smart-Template_Spreadsheet.xlsx";
import instructions from "./attachments/TEEM_Calculator_Instructions_For_Use.pdf";
import directions from "./attachments/Verify_TEEM_Calculator_Smart_Template_21Sept2023v2.8d_BLANK.pdf";
import examples from "./attachments/TEEM_Calculator_Smart_Template_EXAMPLES_R1_FINAL.pdf";


function App() {
  function exportToCsv(filename, rows) {
    var processRow = function (row) {
      var finalVal = "";
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? "" : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ",";
        finalVal += result;
      }
      return finalVal + "\n";
    };

    var csvFile = "";
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  /* Once the user uploads the .xlsx file, start below */
  const onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();
    var verification_pass = true;

    var parts = file.name.split(".");
    var file_type = parts[parts.length - 1].toLowerCase();

    /* Verifies that the uploaded file has .xlsx extension */
    if (file_type == "xlsx") {
      /* Saves file name for later naming of resulting csv file  */
      const uploadname = file.name.substr(0, file.name.indexOf(".xlsx"));

      const results_data = [];

      /* Set up csv headers for applyTSL = yes */
      const tsl_data = [];

      const database_results = [];

      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = read(bstr, { type: "binary" });

        const submission_info_data = [];
        const step1_data = [];

        

        /* Instantiating Submission Info variables */
        var submission_number = "";
        var sponsor_name = "";
        var device_name = "";
        var submitter_name = "";
        var report_identifier = "";
        var test_article_identifier = "";

        /* Instantiating Step 1 variables */
        /* 1C3 */
        var contact_category = "";
        /* 1C4 */
        var duration_category = "";
        /* 1C5 */
        var contact_type = "";
        /* 1C6 */
        var single_use = "";
        /* 1C7 */
        var cumulative_exposure = "";
        /* 1C8 */
        var category_and_exposure = "";
        /* 1C9 */
        var exposure_duration = "";
        /* 1C10 */
        var data_source = "";
        /* 1C11 */
        var scaling_factor = 0;
        /* 1C12 */
        var population_group = "";
        /* 1C13 */
        let default_weight = 0;
        /* 1C14 */
        var alternative_weight = 0;
        /* 1C16 */
        var apply_tsl = "";

        /* Instantiating Step 2 variables */
        /* 2Ax */
        var casrn_inchikey = "";
        /* 2Bx */
        var tq_extracted = 0.0;
        /* 2Cx */
        var cohort_of_concern = "";
        /* 2Dx */
        var genotoxic_carcinogenic = "";
        /* 2Ex */
        var pod_available = "";
        /* 2Fx */
        var ti_1d = 0;
        /* 2Gx */
        var ti_30d = 0;
        /* 2Hx */
        var ti_365d = 0;
        /* 2Ix */
        var ti_366d = 0;

        /* Instantiating Step 3 variables */
        /* 3Dx */
        var tq_exposure = 0.0;
        /* 3Gx */
        var eed_1d = 0;
        /* 3Hx */
        var eed_30d = 0;
        /* 3Ix */
        var eed_365d = 0;
        /* 3Jx */
        var eed_366d = 0;
        /* 3Kx */
        var mos_1d = 0;
        /* 3Lx */
        var mos_30d = 0;
        /* 3Mx */
        var mos_365d = 0;
        /* 3Nx */
        var mos_366d = 0;

        /* Extra variables for Step 3 */
        var mos_1d_na = "";
        var mos_30d_na = "";
        var mos_365d_na = "";
        var mos_366d_na = "";
        var tsl_value_more30_na = "";

        /* Instantiating TSL variables */
        /* 4Gx */
        var tsl_value_less30 = 0;
        /* 4Hx */
        var tsl_tq_ratio_less30 = 0;
        /* 4Ix */
        var tq_exceed_tsl_less30 = "";
        /* 4Jx */
        var tsl_value_more30 = 0;
        /* 4Kx */
        var tsl_tq_ratio_more30 = 0;
        /* 4Lx */
        var tq_exceed_tsl_more30 = "";

        /* Patterns for pattern matching*/
        const valid_pattern = /\w*DATA IS VERIFIABLE\w*/;
        const empty_pattern = /^,*$/;
        const yes_pattern = /^Yes.*/;
        const longterm_pattern = /^Long-term.*/;
        const limited_pattern = /^Limited.*/;
        const no_pattern = /^No.*/;
        const yes_limited_pattern = /^Yes.*limited.*/;
        const yes_prolonged_pattern = /^Yes.*prolonged.*/;
        const yes_longterm_pattern = /^Yes.*long-term.*/;

        /* Iterates through all sheets of the uploaded spreadsheet */
        for (let i = 0; i <= 2; i++) {
          const wsname = wb.SheetNames[i];
          const ws = wb.Sheets[wsname];
          const data = utils.sheet_to_csv(ws, { header: 1 });
          const lines = data.split("\n");

          /* SUBMISSION INFO sheet */
          if (wsname === "SUBMISSION INFO") {
            /* Reads in rows [2, 7] for submission info worksheet */
            for (let j = 2; j <= 7; j++) {
              const line_cols = lines[j].split(",");
              submission_info_data[j - 2] = line_cols[1];
            }

            /* Filling in variables from the worksheet array */
            submission_number = submission_info_data[0];
            sponsor_name = submission_info_data[1];
            device_name = submission_info_data[2];
            submitter_name = submission_info_data[3];
            report_identifier = submission_info_data[4];
            test_article_identifier = submission_info_data[5];

            /* Submission Info Console Checks: Uncomment the lines below to check values in website console */
            // console.log("submission_number: " + submission_number);
            // console.log("sponsor_name: " + sponsor_name);
            // console.log("device_name: " + device_name);
            // console.log("submitter_name: " + submitter_name);
            // console.log("report_identifier: " + report_identifier);
            // console.log("test_article_identifier: " + test_article_identifier);

            /* Pushing the template for TSL=No onto the resulting csv file */
            results_data.unshift(
              ["SUBMISSION INFO", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Submission Number: ", submission_number, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Sponsor Name: ", sponsor_name, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Device Name: ", device_name, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Submitter Name: ", submitter_name, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Report Identifier: ", report_identifier, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Test Article Identifier: ", test_article_identifier, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
            );

            /* Pushing the template for TSL=Yes onto the resulting csv file (note: the number of array entries must be consistent, hence the extra ""'s) */
            tsl_data.unshift(
              ["SUBMISSION INFO", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Submission Number: ", submission_number, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Sponsor Name: ", sponsor_name, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Device Name: ", device_name, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Submitter Name: ", submitter_name, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Report Identifier: ", report_identifier, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["Test Article Identifier: ", test_article_identifier, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
              ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
            );
          }

          /* STEP 1 ENTER DEVICE INFO sheet */
          if (wsname === "STEP 1 ENTER DEVICE INFO") {
            /* Reads in rows [2, 13] for Step 1 fields */
            for (let j = 2; j <= 13; j++) {
              const line_cols = lines[j].split(",");
              step1_data[j - 2] = line_cols[2];
            }

            /* Checking for Smart Template Step 1 Verification Pass */
            const q3_pass = lines[4].split(",")[4];
            const q4_pass = lines[5].split(",")[4];
            const q5_pass = lines[6].split(",")[4];
            const q6_pass = lines[7].split(",")[4];
            const q7_pass = lines[8].split(",")[4];

            if (lines[0].split(",")[0] != "MEDICAL DEVICE USE INFORMATION" || !valid_pattern.test(q3_pass) || !valid_pattern.test(q4_pass) || !valid_pattern.test(q5_pass) || !valid_pattern.test(q6_pass) || !valid_pattern.test(q7_pass)) {
              alert("The spreadsheet uploaded contains worksheet verification failures in Sheet 1. Please review and fix the failures and re-upload the spreadsheet.");
              console.log("The spreadsheet uploaded contains worksheet verification failures in Sheet 1. Please review and fix the failures and re-upload the spreadsheet.");
              verification_pass = false;
            }

            /* Continue only if verification passes */
            if (verification_pass) {
              /* Filling in variables from the worksheet array */
              contact_category = step1_data[0];
              duration_category = step1_data[1];
              contact_type = step1_data[2];
              single_use = step1_data[3];
              cumulative_exposure = step1_data[4];
              category_and_exposure = step1_data[5];
              exposure_duration = step1_data[6];
              data_source = step1_data[7];
              scaling_factor = parseFloat(step1_data[8], 10);
              population_group = step1_data[9];
              default_weight = step1_data[10] == "" ? 0 : parseFloat(step1_data[10], 10); // making sure not empty
              alternative_weight = step1_data[11] == "" ? 0 : parseFloat(step1_data[11], 10); // making sure not empty
              apply_tsl = lines[15].split(",")[2];

              /* Exposure Period: converting string answers to exposure periods {1,2,3,4} for easy checks later */
              var single_exp = 1;
              if (duration_category == "Prolonged (2-<30d)") {
                single_exp = 2;
              } else if (duration_category == "Long-term (31-<365d)") {
                single_exp = 3;
              } else if (duration_category == "Long-term (>366d)") {
                single_exp = 4;
              }
              var cumulative_exp = 0;
              if (yes_limited_pattern.test(category_and_exposure)) {
                cumulative_exp = 1;
              } else if (yes_prolonged_pattern.test(category_and_exposure) || exposure_duration == "Prolonged (2-<30d)") {
                cumulative_exp = 2;
              } else if (yes_longterm_pattern.test(category_and_exposure) || exposure_duration == "Long-term (31-<365d)") {
                cumulative_exp = 3;
              } else if (exposure_duration == "Long-term (>366d)") {
                cumulative_exp = 4;
              }
              /* Exposure Period Console Checks: Uncomment the lines below to check values in website console */
              // console.log("single_exp: " + single_exp);
              // console.log("cumulative_exp: " + cumulative_exp);

              /* Pushing the template for TSL=No onto the resulting csv file */
              results_data.push(
                ["STEP 1 ENTER DEVICE INFO", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Device Contact Category?*", contact_category, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Device Duration Category?*", duration_category, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Device Contact Type?*", contact_type, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Is Device Repeatedly Used?*", single_use, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Does Cumulative Exposure Apply?*", cumulative_exposure, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Is Duration Category (Q2) the Cumulative Exposure Duration?*", category_and_exposure, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Cumulative Exposure Duration?*", exposure_duration, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Exposure Data Source?*", data_source, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Scaling Factor?*", scaling_factor, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Population Group?*", population_group, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Alternative Patient/User Body Weight (kg) (optional)*", alternative_weight, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Apply TSL?*", apply_tsl, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "DATA FORMATTED TO IMPORT INTO DATABASE"],
                ["REPORTABLE EXTRACTABLES DATA", "", "TOXICOLOGICAL RISK ASSESSMENT CALCULATIONS", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Chemical Exposure Data", "STEP 2 ENTER TQ DATA", "Toxicity Info", "", "",  "", "", "", "", "Calculated EEDmax Values (mcg/kg/d)", "", "", "", "Calculated MOS Values", "", "", "", "", "", "", "", "", "", "", "", "", "", "SUBMISSION INFO", "", "", "", "", "", "STEP 1: ENTER DEVICE INFO", "", "", "", "", "", "", "", "", "", "", "Chemical Exposure Data", "STEP 2: ENTER TQ DATA", "", "", "", "TSL<30d", "", "", "TSL>30d", "", "", "Toxicity Info", "", "", "", "Calculated EEDmax Values (mcg/kg/d)", "", "", "", "Calculated MOS Values", "", "", "" ],
                [
                  "CASRN or InChlKey",
                  "TQexposure (mcg)",
                  "Cohort of Concern & Excluded Compounds?",
                  "Genotoxic / Carcinogenic?*",
                  "Chemical PoD Available?",
                  "TI<1d",
                  "TI2-30d",
                  "TI31-365d",
                  "TI>366d",
                  "EED<1d",
                  "EED2-<30d",
                  "EED31-<365d",
                  "EED>366d",
                  "MOS<1d",
                  "MOS>2-<30d",
                  "MOS>31-<365d",
                  "MOS>366d",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "CSV Filename",
                  "Submission Number",
                  "Sponsor Name",
                  "Device Name",
                  "Submitter Name",
                  "Report Identifier",
                  "Test Article Identifier",
                  "What is the Device Contact Category?",
                  "What is the Device Duration Category?",
                  "What is the Device Contact Type?",
                  "Is Device Repeatedly Used?",
                  "Does Cumulative Exposure Apply?",
                  "Is Duration Category (Q2) the Cumulative Exposure Duration?",
                  "What is the Cumulative Exposure Duration?",
                  "What is the Exposure Data Source?",
                  "What is the Scaling Factor?",
                  "What is the Population Group?",
                  "Alternative Patient/User Body Weight (kg) (optional)",
                  "Apply TSL?",
                  "CASRN or InChlKey",
                  "TQexposure (mcg)",
                  "Cohort of Concern & Excluded Compounds?",
                  "Genotoxic / Carcinogenic?",
                  "Chemical PoD Available?",
                  "TSL<30d Value",
                  "TSL<30d : TQ Ratio",
                  "Does TQ Exceed TSL<30d?",
                  "TSL>30d Value",
                  "TSL>30d : TQ Ratio",
                  "Does TQ Exceed TSL>30d?",
                  "TI<1d",
                  "TI2-30d",
                  "TI31-365d",
                  "TI>366d",
                  "EED<1d",
                  "EED2-<30d",
                  "EED31-<365d",
                  "EED>366d",
                  "MOS<1d",
                  "MOS>2-<30d",
                  "MOS>31-<365d",
                  "MOS>366d",
                ]
              );

              /* Pushing the template for TSL=Yes onto the resulting csv file */
              tsl_data.push(
                ["STEP 1 ENTER DEVICE INFO", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Device Contact Category?*", contact_category, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Device Duration Category?*", duration_category, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Device Contact Type?*", contact_type, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Is Device Repeatedly Used?*", single_use, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Does Cumulative Exposure Apply?*", cumulative_exposure, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Is Duration Category (Q2) the Cumulative Exposure Duration?*", category_and_exposure, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Cumulative Exposure Duration?*", exposure_duration, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Exposure Data Source?*", data_source, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Scaling Factor?*", scaling_factor, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["What is the Population Group?*", population_group, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Alternative Patient/User Body Weight (kg) (optional)*", alternative_weight, "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Apply TSL?*", apply_tsl, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "DATA FORMATTED TO IMPORT INTO DATABASE"],
                ["REPORTABLE EXTRACTABLES DATA", "", "TSL CALCULATIONS", "", "", "", "", "", "TOXICOLOGICAL RISK ASSESSMENT CALCULATIONS", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Chemical Exposure Data", "STEP 2 ENTER TQ DATA", "TSL<30d", "", "", "TSL>30d", "", "", "Toxicity Info", "", "", "", "", "", "", "Calculated EEDmax Values (mcg/kg/d)", "", "", "", "Calculated MOS Values", "", "", "", "", "", "", "", "SUBMISSION INFO", "", "", "", "", "", "STEP 1: ENTER DEVICE INFO", "", "", "", "", "", "", "", "", "", "", "Chemical Exposure Data", "STEP 2: ENTER TQ DATA", "", "", "", "TSL<30d", "", "", "TSL>30d", "", "", "Toxicity Info", "", "", "", "Calculated EEDmax Values (mcg/kg/d)", "", "", "", "Calculated MOS Values", "", "", "" ],
                [
                  "CASRN or InChlKey",
                  "TQexposure (mcg)",
                  "TSL<30d Value",
                  "TSL<30d : TQ Ratio",
                  "Does TQ Exceed TSL<30d?",
                  "TSL>30d Value",
                  "TSL>30d : TQ Ratio",
                  "Does TQ Exceed TSL>30d?",
                  "Cohort of Concern & Excluded Compounds?",
                  "Genotoxic / Carcinogenic?*",
                  "Chemical PoD Available?",
                  "TI<1d",
                  "TI2-30d",
                  "TI31-365d",
                  "TI>366d",
                  "EED<1d",
                  "EED2-<30d",
                  "EED31-<365d",
                  "EED>366d",
                  "MOS<1d",
                  "MOS>2-<30d",
                  "MOS>31-<365d",
                  "MOS>366d",
                  "",
                  "",
                  "",
                  "CSV Filename",
                  "Submission Number",
                  "Sponsor Name",
                  "Device Name",
                  "Submitter Name",
                  "Report Identifier",
                  "Test Article Identifier",
                  "What is the Device Contact Category?",
                  "What is the Device Duration Category?",
                  "What is the Device Contact Type?",
                  "Is Device Repeatedly Used?",
                  "Does Cumulative Exposure Apply?",
                  "Is Duration Category (Q2) the Cumulative Exposure Duration?",
                  "What is the Cumulative Exposure Duration?",
                  "What is the Exposure Data Source?",
                  "What is the Scaling Factor?",
                  "What is the Population Group?",
                  "Alternative Patient/User Body Weight (kg) (optional)",
                  "Apply TSL?",
                  "CASRN or InChlKey",
                  "TQexposure (mcg)",
                  "Cohort of Concern & Excluded Compounds?",
                  "Genotoxic / Carcinogenic?",
                  "Chemical PoD Available?",
                  "TSL<30d Value",
                  "TSL<30d : TQ Ratio",
                  "Does TQ Exceed TSL<30d?",
                  "TSL>30d Value",
                  "TSL>30d : TQ Ratio",
                  "Does TQ Exceed TSL>30d?",
                  "TI<1d",
                  "TI2-30d",
                  "TI31-365d",
                  "TI>366d",
                  "EED<1d",
                  "EED2-<30d",
                  "EED31-<365d",
                  "EED>366d",
                  "MOS<1d",
                  "MOS>2-<30d",
                  "MOS>31-<365d",
                  "MOS>366d",
                ]
              );

              /* Sheet 1 Console Checks: Uncomment the lines below to check values in website console */
              // console.log("Step 1 Console Checks: ");
              // console.log("contact_category: " + contact_category);
              // console.log("duration_category: " + duration_category);
              // console.log("contact_type: " + contact_type);
              // console.log("single_use: " + single_use);
              // console.log("cumulative_exposure: " + cumulative_exposure);
              // console.log("category_and_exposure: " + category_and_exposure);
              // console.log("exposure_duration: " + exposure_duration);
              // console.log("data_source: " + data_source);
              // console.log("scaling_factor: " + scaling_factor);
              // console.log("population_group: " + population_group);
              // console.log("default_weight: " + default_weight);
              // console.log("alternative_weight: " + alternative_weight);
              // console.log("apply_tsl: " + apply_tsl);
            }
          } else if (wsname === "STEP 2 ENTER TQ DATA") {
            /* STEP 2 ENTER TQ DATA sheet */

            /* Starts reading in from first chemical entry, row 5 */
            var j = 9;
            var step2_data = lines[j].split(",");

            if (lines[0].split(",")[1] == "FAIL" || lines[0].split(",")[1] == "") {
              alert("The spreadsheet uploaded contains worksheet verification failures in Sheet 2. Please review and fix the failures and re-upload the spreadsheet.");
              // console.log(
              //   "The spreadsheet uploaded contains worksheet verification failures in Sheet 2. Please review and fix the failures and re-upload the spreadsheet."
              // );
              verification_pass = false;
            }

            if (verification_pass) {
              /* Goes until 987 because we want to account for possible empty rows */
              while (j <= 987) {
                if (!empty_pattern.test(lines[j]) && lines[j].split(",")[9] == "PASS") {
                  /* Resetting Sheet 2 for processing each row */
                  tq_exposure = 0.0;
                  eed_1d = 0;
                  eed_30d = 0;
                  eed_365d = 0;
                  eed_366d = 0;
                  mos_1d = 0;
                  mos_30d = 0;
                  mos_365d = 0;
                  mos_366d = 0;
                  tsl_value_less30 = 0;
                  tsl_tq_ratio_less30 = 0;
                  tq_exceed_tsl_less30 = "";
                  tsl_value_more30 = 0;
                  tsl_tq_ratio_more30 = 0;
                  tq_exceed_tsl_more30 = "";

                  /* Resetting TSL variables for processing each row */
                  mos_1d_na = "";
                  mos_30d_na = "";
                  mos_365d_na = "";
                  mos_366d_na = "";
                  tsl_value_more30_na = "";

                  /* Reading in next row */
                  casrn_inchikey = step2_data[0];
                  tq_extracted = parseFloat(step2_data[1], 10);
                  cohort_of_concern = step2_data[2];
                  genotoxic_carcinogenic = step2_data[3];
                  pod_available = step2_data[4];
                  ti_1d = step2_data[5] == "" ? 0 : parseFloat(step2_data[5], 10);
                  ti_30d = step2_data[6] == "" ? 0 : parseFloat(step2_data[6], 10);
                  ti_365d = step2_data[7] == "" ? 0 : parseFloat(step2_data[7], 10);
                  ti_366d = step2_data[8] == "" ? 0 : parseFloat(step2_data[8], 10);

                  /* Sheet 2 Console Checks: Uncomment the lines below to check values in website console */
                  // console.log("Step 2 Console Checks: ");
                  // console.log("casrn_inchikey: " + casrn_inchikey);
                  // console.log("tq_extracted: " + tq_extracted);
                  // console.log("cohort_of_concern: " + cohort_of_concern);
                  // console.log("genotoxic_carcinogenic: " + genotoxic_carcinogenic);
                  // console.log("pod_available: " + pod_available);
                  console.log("ti_1d: " + ti_1d);
                  console.log("ti_30d: " + ti_30d);
                  console.log("ti_365d: " + ti_365d);
                  console.log("ti_366d: " + ti_366d);

                  /* TSL work begins below */
                  if (apply_tsl == "Yes") {
                    /* 4Gx */
                    /* Constant value */
                    tsl_value_less30 = 120;

                    /* 4Hx */
                    /* 4Gx / (2D(x+1) * 1C11) */
                    tsl_tq_ratio_less30 = tsl_value_less30 / (tq_extracted * scaling_factor);

                    /* 4Ix */
                    tq_exceed_tsl_less30 = tsl_tq_ratio_less30 < 1 ? "Yes" : "No";
                    console.log("before the if statement");
                    /* 4Jx */
                    /* if not (1C4 == "Limited/Prolonged (<30d)") */
                    console.log("duration: " + duration_category);
                    console.log("single_exp: " + single_exp);
                    if (single_exp < 3) {
                      console.log("just the outside");
                      tsl_value_more30 = 600;

                      /* 4Kx */
                      /* if not (1C7 starts with "Yes" && (1C4 starts with "Long-term" || 1C9 starts with "Long-term")) */
                      if ((!(yes_pattern.test(cumulative_exposure)) && longterm_pattern.test(duration_category)) || longterm_pattern.test(exposure_duration)) {
                        /* 4Jx / (2D(x+1) * 1C11) */
                        console.log("checked inside");
                        tsl_tq_ratio_more30 = tsl_value_more30 / (tq_extracted * scaling_factor);
                        console.log(" tsl_tq_ratio_more30: " +  tsl_tq_ratio_more30);
                        /* 4Lx */
                        tq_exceed_tsl_more30 = tsl_tq_ratio_more30 < 1 ? "Yes" : "No";
                      }
                    }
                    /* single_exp == 4 */
                    else {
                      tsl_value_more30 = 600;
                      tsl_tq_ratio_more30 = tsl_value_more30 / (tq_extracted * scaling_factor);
                      tq_exceed_tsl_more30 = tsl_tq_ratio_more30 < 1 ? "Yes" : "No";
                    }
                  }

                  /* EED and MOS calculations begin below */

                  /* 2Bx * 1C11 */
                  tq_exposure = tq_extracted * scaling_factor;

                  /* eed_1d and mos_1d */
                  if (apply_tsl == "No" || cohort_of_concern == "Yes" || tq_exceed_tsl_less30 == "Yes") {
                    var ti_val = ti_1d != 0 ? ti_1d : ti_30d != 0 ? ti_30d : ti_365d != 0 ? ti_365d : ti_366d != 0 ? ti_366d : genotoxic_carcinogenic == "Yes" ? 2.0 : 1.5;

                    /* 3Ix */
                    /* eed_1d */
                    eed_1d = (tq_extracted * scaling_factor) / (alternative_weight == 0 ? default_weight : alternative_weight);

                    /* 3Kx */
                    /* mos_1d */
                    mos_1d = ti_val / eed_1d;
                  }

                  /* eed_30d and mos_30d */
                  if (apply_tsl == "No" || cohort_of_concern == "Yes" || tq_exceed_tsl_less30 == "Yes") {
                    var ti_val = ti_30d != 0 ? ti_30d : ti_365d != 0 ? ti_365d : ti_366d != 0 ? ti_366d : genotoxic_carcinogenic == "Yes" ? 2.0 : 1.5;

                    /* 3Jx */
                    /* eed_30d */
                    if (single_exp >= 2) {
                      if (limited_pattern.test(duration_category) && (yes_prolonged_pattern.test(category_and_exposure) || no_pattern.test(category_and_exposure))) {
                        eed_30d = eed_1d;
                      } else if ((single_use == "No" || single_use == "NA") || cumulative_exp >= 2) {
                        eed_30d = (tq_extracted * scaling_factor) / (alternative_weight == 0 ? default_weight : alternative_weight) / 2.0;
                      }
                    }

                    /* 3Lx */
                    /* mos_30d */
                    // Not single use, so refer to single use exposure time period
                    if ((single_use == "No" || single_use == "NA") && single_exp >= 2) {
                      mos_30d = ti_val.toFixed(2) / eed_30d.toFixed(4);
                    }
                    // Yes single use (repeated), and refer to Q7 for the exposure time period
                    else if (single_use == "Yes" && no_pattern.test(category_and_exposure)) {
                      // use EEDmax<1d
                      if (single_exp == 1 && cumulative_exp >= 2) {
                        mos_30d = ti_val.toFixed(2) / eed_1d.toFixed(4);
                      }
                      // use EEDmax2<-30d
                      else if (single_exp == 2 && cumulative_exp >= 3) {
                        mos_30d = ti_val.toFixed(2) / eed_30d.toFixed(4);
                      }
                    }
                    // Yes single use (repeated), and refer to Q6 for the exposure time period
                    else if (single_use == "Yes" && yes_pattern.test(category_and_exposure) && single_exp >= 2) {
                      // use EEDmax<1d
                      if (yes_limited_pattern.test(category_and_exposure)) {
                        mos_30d = ti_val.toFixed(2) / eed_1d.toFixed(4);
                      }
                      // use EEDmax2<-30d
                      else {
                        mos_30d = ti_val.toFixed(2) / eed_30d.toFixed(4);
                      }
                    }
                  }
                  console.log("couldn't even get inside");
                  /* eed_365d and mos_365d */
                  if (apply_tsl == "No" || cohort_of_concern == "Yes" || tq_exceed_tsl_more30 == "Yes") {
                    var ti_val = ti_365d != 0 ? ti_365d : ti_366d != 0 ? ti_366d : genotoxic_carcinogenic == "Yes" ? 0.3333 : 1.5;

                    /* 3Ix */
                    /* eed_365d */
                    if (single_exp >= 3) {
                      if (yes_pattern.test(cumulative_exposure) && cumulative_exp == 4) {
                        eed_365d = eed_30d;
                      } else if ((single_use == "No" || single_use == "NA") || cumulative_exp >= 3) {
                        eed_365d = (tq_extracted * scaling_factor) / (alternative_weight == 0 ? default_weight : alternative_weight) / 31.0;
                      }
                    }

                    console.log("running, about to enter single use");
                    /* 3Mx */
                    /* mos_365d */
                    // Not single use, so refer to single use exposure time period
                    if ((single_use == "No" || single_use == "NA") && single_exp >= 3) {
                      mos_365d = ti_val.toFixed(4) / eed_365d.toFixed(4);
                    }
                    // Yes single use (repeated), and refer to Q7 for the exposure time period
                    else if (single_use == "Yes" && no_pattern.test(category_and_exposure)) {
                      console.log("running, about to enter single use");
                      // use EEDmax<1d
                      if (single_exp == 1 && cumulative_exp >= 3) { // CHANGED back to as it was before
                        console.log("exp = 1");
                        mos_365d = ti_val.toFixed(4) / eed_1d.toFixed(4);
                        console.log(mos_365d);
                      }
                      // use EEDmax2<-30d
                      else if (single_exp == 2 && cumulative_exp >= 3) {
                        console.log("exp = 2");
                        mos_365d = ti_val.toFixed(4) / eed_30d.toFixed(4);
                        console.log(mos_365d);
                      }
                      // use EEDmax31<-365d
                      else if (single_exp == 3 && cumulative_exp >= 4) { // CHANGED back to as it was before 
                        console.log("exp = 3 inside");
                        mos_365d = ti_val.toFixed(4) / eed_365d.toFixed(4);
                        console.log(mos_365d);
                      }
                    }
                    /* ERROR ISSUE */
                    // Yes single use (repeated), and refer to Q6 for the exposure time period
                    else if (single_use == "Yes" && yes_pattern.test(category_and_exposure) && single_exp >= 3) {
                      // use EEDmax<1d
                      if (yes_limited_pattern.test(category_and_exposure)) {
                        mos_365d = ti_val.toFixed(4) / eed_1d.toFixed(4);
                      }
                      // use EEDmax2<-30d
                      else if (yes_prolonged_pattern.test(category_and_exposure)) {
                        mos_365d = ti_val.toFixed(4) / eed_30d.toFixed(4);
                      }
                      // use EEDmax31<-365d
                      else {
                        mos_365d = ti_val.toFixed(4) / eed_30d.toFixed(4);
                      }
                    }
                  }

                  /* eed_366d and mos366d */
                  if (apply_tsl == "No" || cohort_of_concern == "Yes" || tq_exceed_tsl_more30 == "Yes") {
                    var ti_val = ti_366d != 0 ? ti_366d : genotoxic_carcinogenic == "Yes" ? 0.1667 : 1.5;

                    /* 3Jx */
                    /* eed_366d */
                    if (single_exp == 4) {
                      if (yes_pattern.test(cumulative_exposure) && cumulative_exp == 4) {
                        eed_366d = eed_30d;
                      } else if (single_use == "No" || single_use == "NA" || cumulative_exp == 4) {
                        eed_366d = (tq_extracted * scaling_factor) / (alternative_weight == 0 ? default_weight : alternative_weight) / 366.0;
                      }
                    }

                    /* 3Nx */
                    /* mos_366d */
                    // Not single use, so refer to single use exposure time period
                    if ((single_use == "No" || single_use == "NA") && single_exp == 4) {
                      mos_366d = ti_val.toFixed(2) / eed_366d.toFixed(4);
                    }
                    // Yes single use (repeated), and refer to Q7 for the exposure time period
                    else if (single_use == "Yes" && no_pattern.test(category_and_exposure) && cumulative_exp == 4) {
                      // use EEDmax<1d
                      if (single_exp == 1) {
                        mos_366d = ti_val.toFixed(4) / eed_1d.toFixed(4);
                      }
                      // use EEDmax2<-30d
                      else if (single_exp == 2) {
                        mos_366d = ti_val.toFixed(4) / eed_30d.toFixed(4);
                      }
                      // use EEDmax31<-365d
                      else if (single_exp == 3) {
                        mos_366d = ti_val.toFixed(4) / eed_365d.toFixed(4);
                      }
                      // use EEDmax>366d
                      else {
                        mos_366d = ti_val.toFixed(4) / eed_366d.toFixed(4);
                      }
                    }
                    // Yes single use (repeated), and refer to Q6 for the exposure time period
                    else if (single_use == "Yes" && yes_pattern.test(category_and_exposure) && single_exp == 4) {
                      // use EEDmax<1d
                      if (yes_limited_pattern.test(category_and_exposure)) {
                        mos_366d = ti_val.toFixed(4) / eed_1d.toFixed(4);
                      }
                      // use EEDmax2<-30d
                      else if (yes_prolonged_pattern.test(category_and_exposure)) {
                        mos_366d = ti_val.toFixed(4) / eed_30d.toFixed(4);
                      }
                      // use EEDmax31<-365d
                      else if (yes_longterm_pattern.test(category_and_exposure)) {
                        mos_366d = ti_val.toFixed(4) / eed_365d.toFixed(4);
                      }
                      // use EEDmax>366d
                      else {
                        mos_366d = ti_val.toFixed(4) / eed_366d.toFixed(4);
                      }
                    }
                  }

                  /* Sheet 3 Console Checks: Uncomment the lines below to check values in website console */
                  // console.log("Step 3 Console Checks: ");
                  // console.log("casrn_inchikey: " + casrn_inchikey);
                  // console.log("tq_exposure: " + tq_exposure);
                  // console.log("cohort_of_concern: " + cohort_of_concern);
                  // console.log("genotoxic_carcinogenic: " + genotoxic_carcinogenic);
                  // console.log("pod_available: " + pod_available);
                  // console.log("eed_1d: " + eed_1d.toFixed(4));
                  // console.log("eed_30d: " + eed_30d.toFixed(4));
                  // console.log("eed_365d: " + eed_365d.toFixed(4));
                  // console.log("eed_366d: " + eed_366d.toFixed(4));
                  // console.log("mos_1d: " + mos_1d.toFixed(4));
                  // console.log("mos_30d: " + mos_30d.toFixed(4));
                  // console.log("mos_365d: " + mos_365d.toFixed(4));
                  // console.log("mos_366d: " + mos_366d.toFixed(4));

                  /* Sheet 4 Console Checks: Uncomment the lines below to check values in website console */
                  // console.log("Step 4 Console Checks: ");
                  // console.log("tsl_value_less30: " + tsl_value_less30);
                  // console.log("tsl_tq_ratio_less30: " + tsl_tq_ratio_less30);
                  // console.log("tq_exceed_tsl_less30: " + tq_exceed_tsl_less30);
                  // console.log("tsl_value_more30: " + tsl_value_more30);
                  // console.log("tsl_tq_ratio_more30: " + tsl_tq_ratio_more30);
                  // console.log("tq_exceed_tsl_more30: " + tq_exceed_tsl_more30);
                  var current = new Date();
                  var full_date = current.getMonth() + 1 + "-" + current.getDate() + "-" + current.getFullYear() + ".csv";

                  if (apply_tsl == "Yes") {
                    tsl_data.push([
                      casrn_inchikey,
                      tq_exposure.toString(),
                      tsl_value_less30 == 0 ? "" : tsl_value_less30.toFixed(4).toString(),
                      tsl_tq_ratio_less30 == 0 ? "" : tsl_tq_ratio_less30.toFixed(4).toString(),
                      tq_exceed_tsl_less30,
                      tsl_value_more30 == 0 ? "" : tsl_value_more30.toFixed(4).toString(),
                      tsl_tq_ratio_more30 == 0 ? "" : tsl_tq_ratio_more30.toFixed(4).toString(),
                      tq_exceed_tsl_more30,
                      cohort_of_concern,
                      genotoxic_carcinogenic,
                      pod_available,
                      ti_1d == 0 ? "" : ti_1d.toFixed(4).toString(),
                      ti_30d == 0 ? "" : ti_30d.toFixed(4).toString(),
                      ti_365d == 0 ? "" : ti_365d.toFixed(4).toString(),
                      ti_366d == 0 ? "" : ti_366d.toFixed(4).toString(),
                      eed_1d == 0 ? "" : eed_1d.toFixed(4).toString(),
                      eed_30d == 0 ? "" : eed_30d.toFixed(4).toString(),
                      eed_365d == 0 ? "" : eed_365d.toFixed(4).toString(),
                      eed_366d == 0 ? "" : eed_366d.toFixed(4).toString(),
                      mos_1d_na == "" ? (mos_1d == 0 ? "" : mos_1d.toFixed(4).toString()) : mos_1d_na,
                      mos_30d_na == "" ? (mos_30d == 0 ? "" : mos_30d.toFixed(4).toString()) : mos_30d_na,
                      mos_365d_na == "" ? (mos_365d == 0 ? "" : mos_365d.toFixed(4).toString()) : mos_365d_na,
                      mos_366d_na == "" ? (mos_366d == 0 ? "" : mos_366d.toFixed(4).toString()) : mos_366d_na,
                      /*new code lol*/ 
                      "",
                      "",
                      "",
                      (uploadname + "_Results_" + full_date),
                      submission_number,
                      sponsor_name,
                      device_name,
                      submitter_name,
                      report_identifier,
                      test_article_identifier,
                      contact_category,
                      duration_category,
                      contact_type,
                      single_use,
                      cumulative_exposure,
                      category_and_exposure,
                      exposure_duration,
                      data_source,
                      scaling_factor,
                      population_group,
                      alternative_weight,
                      apply_tsl,
                      casrn_inchikey,
                      tq_exposure.toString(),
                      cohort_of_concern,
                      genotoxic_carcinogenic,
                      pod_available,
                      tsl_value_less30 == 0 ? "" : tsl_value_less30.toFixed(4).toString(),
                      tsl_tq_ratio_less30 == 0 ? "" : tsl_tq_ratio_less30.toFixed(4).toString(),
                      tq_exceed_tsl_less30,
                      tsl_value_more30 == 0 ? "" : tsl_value_more30.toFixed(4).toString(),
                      tsl_tq_ratio_more30 == 0 ? "" : tsl_tq_ratio_more30.toFixed(4).toString(),
                      tq_exceed_tsl_more30,
                      ti_1d == 0 ? "" : ti_1d.toFixed(4).toString(),
                      ti_30d == 0 ? "" : ti_30d.toFixed(4).toString(),
                      ti_365d == 0 ? "" : ti_365d.toFixed(4).toString(),
                      ti_366d == 0 ? "" : ti_366d.toFixed(4).toString(),
                      eed_1d == 0 ? "" : eed_1d.toFixed(4).toString(),
                      eed_30d == 0 ? "" : eed_30d.toFixed(4).toString(),
                      eed_365d == 0 ? "" : eed_365d.toFixed(4).toString(),
                      eed_366d == 0 ? "" : eed_366d.toFixed(4).toString(),
                      mos_1d_na == "" ? (mos_1d == 0 ? "" : mos_1d.toFixed(4).toString()) : mos_1d_na,
                      mos_30d_na == "" ? (mos_30d == 0 ? "" : mos_30d.toFixed(4).toString()) : mos_30d_na,
                      mos_365d_na == "" ? (mos_365d == 0 ? "" : mos_365d.toFixed(4).toString()) : mos_365d_na,
                      mos_366d_na == "" ? (mos_366d == 0 ? "" : mos_366d.toFixed(4).toString()) : mos_366d_na,
                    ]);
                    // database_results.push([
                    //   casrn_inchikey,
                    //   tq_exposure.toString(),
                    //   cohort_of_concern,
                    //   genotoxic_carcinogenic,
                    //   pod_available,
                    //   tsl_value_less30 == 0 ? "" : tsl_value_less30.toFixed(4).toString(),
                    //   tsl_tq_ratio_less30 == 0 ? "" : tsl_tq_ratio_less30.toFixed(4).toString(),
                    //   tq_exceed_tsl_less30,
                    //   tsl_value_more30 == 0 ? "" : tsl_value_more30.toFixed(4).toString(),
                    //   tsl_tq_ratio_more30 == 0 ? "" : tsl_tq_ratio_more30.toFixed(4).toString(),
                    //   tq_exceed_tsl_more30,
                    //   ti_1d == 0 ? "" : ti_1d.toFixed(4).toString(),
                    //   ti_30d == 0 ? "" : ti_30d.toFixed(4).toString(),
                    //   ti_365d == 0 ? "" : ti_365d.toFixed(4).toString(),
                    //   ti_366d == 0 ? "" : ti_366d.toFixed(4).toString(),
                    //   eed_1d == 0 ? "" : eed_1d.toFixed(4).toString(),
                    //   eed_30d == 0 ? "" : eed_30d.toFixed(4).toString(),
                    //   eed_365d == 0 ? "" : eed_365d.toFixed(4).toString(),
                    //   eed_366d == 0 ? "" : eed_366d.toFixed(4).toString(),
                    //   mos_1d_na == "" ? (mos_1d == 0 ? "" : mos_1d.toFixed(4).toString()) : mos_1d_na,
                    //   mos_30d_na == "" ? (mos_30d == 0 ? "" : mos_30d.toFixed(4).toString()) : mos_30d_na,
                    //   mos_365d_na == "" ? (mos_365d == 0 ? "" : mos_365d.toFixed(4).toString()) : mos_365d_na,
                    //   mos_366d_na == "" ? (mos_366d == 0 ? "" : mos_366d.toFixed(4).toString()) : mos_366d_na,
                    // ]);
                  } else {
                    results_data.push([
                      casrn_inchikey,
                      tq_exposure.toString(),
                      cohort_of_concern,
                      genotoxic_carcinogenic,
                      pod_available,
                      ti_1d == 0 ? "" : ti_1d.toFixed(4).toString(),
                      ti_30d == 0 ? "" : ti_30d.toFixed(4).toString(),
                      ti_365d == 0 ? "" : ti_365d.toFixed(4).toString(),
                      ti_366d == 0 ? "" : ti_366d.toFixed(4).toString(),
                      eed_1d == 0 ? "" : eed_1d.toFixed(4).toString(),
                      eed_30d == 0 ? "" : eed_30d.toFixed(4).toString(),
                      eed_365d == 0 ? "" : eed_365d.toFixed(4).toString(),
                      eed_366d == 0 ? "" : eed_366d.toFixed(4).toString(),
                      mos_1d_na == "" ? (mos_1d == 0 ? "" : mos_1d.toFixed(4).toString()) : mos_1d_na,
                      mos_30d_na == "" ? (mos_30d == 0 ? "" : mos_30d.toFixed(4).toString()) : mos_30d_na,
                      mos_365d_na == "" ? (mos_365d == 0 ? "" : mos_365d.toFixed(4).toString()) : mos_365d_na,
                      mos_366d_na == "" ? (mos_366d == 0 ? "" : mos_366d.toFixed(4).toString()) : mos_366d_na,
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      (uploadname + "_Results_" + full_date),
                      submission_number,
                      sponsor_name,
                      device_name,
                      submitter_name,
                      report_identifier,
                      test_article_identifier,
                      contact_category,
                      duration_category,
                      contact_type,
                      single_use,
                      cumulative_exposure,
                      category_and_exposure,
                      exposure_duration,
                      data_source,
                      scaling_factor,
                      population_group,
                      alternative_weight,
                      apply_tsl,
                      casrn_inchikey,
                      tq_exposure.toString(),
                      cohort_of_concern,
                      genotoxic_carcinogenic,
                      pod_available,
                      tsl_value_less30 == 0 ? "" : tsl_value_less30.toFixed(4).toString(),
                      tsl_tq_ratio_less30 == 0 ? "" : tsl_tq_ratio_less30.toFixed(4).toString(),
                      tq_exceed_tsl_less30,
                      tsl_value_more30 == 0 ? "" : tsl_value_more30.toFixed(4).toString(),
                      tsl_tq_ratio_more30 == 0 ? "" : tsl_tq_ratio_more30.toFixed(4).toString(),
                      tq_exceed_tsl_more30,
                      ti_1d == 0 ? "" : ti_1d.toFixed(4).toString(),
                      ti_30d == 0 ? "" : ti_30d.toFixed(4).toString(),
                      ti_365d == 0 ? "" : ti_365d.toFixed(4).toString(),
                      ti_366d == 0 ? "" : ti_366d.toFixed(4).toString(),
                      eed_1d == 0 ? "" : eed_1d.toFixed(4).toString(),
                      eed_30d == 0 ? "" : eed_30d.toFixed(4).toString(),
                      eed_365d == 0 ? "" : eed_365d.toFixed(4).toString(),
                      eed_366d == 0 ? "" : eed_366d.toFixed(4).toString(),
                      mos_1d_na == "" ? (mos_1d == 0 ? "" : mos_1d.toFixed(4).toString()) : mos_1d_na,
                      mos_30d_na == "" ? (mos_30d == 0 ? "" : mos_30d.toFixed(4).toString()) : mos_30d_na,
                      mos_365d_na == "" ? (mos_365d == 0 ? "" : mos_365d.toFixed(4).toString()) : mos_365d_na,
                      mos_366d_na == "" ? (mos_366d == 0 ? "" : mos_366d.toFixed(4).toString()) : mos_366d_na,
                    ]);
                    // database_results.push([
                    //   casrn_inchikey,
                    //   tq_exposure.toString(),
                    //   cohort_of_concern,
                    //   genotoxic_carcinogenic,
                    //   pod_available,
                    //   tsl_value_less30 == 0 ? "" : tsl_value_less30.toFixed(4).toString(),
                    //   tsl_tq_ratio_less30 == 0 ? "" : tsl_tq_ratio_less30.toFixed(4).toString(),
                    //   tq_exceed_tsl_less30,
                    //   tsl_value_more30 == 0 ? "" : tsl_value_more30.toFixed(4).toString(),
                    //   tsl_tq_ratio_more30 == 0 ? "" : tsl_tq_ratio_more30.toFixed(4).toString(),
                    //   tq_exceed_tsl_more30,
                    //   ti_1d == 0 ? "" : ti_1d.toFixed(4).toString(),
                    //   ti_30d == 0 ? "" : ti_30d.toFixed(4).toString(),
                    //   ti_365d == 0 ? "" : ti_365d.toFixed(4).toString(),
                    //   ti_366d == 0 ? "" : ti_366d.toFixed(4).toString(),
                    //   eed_1d == 0 ? "" : eed_1d.toFixed(4).toString(),
                    //   eed_30d == 0 ? "" : eed_30d.toFixed(4).toString(),
                    //   eed_365d == 0 ? "" : eed_365d.toFixed(4).toString(),
                    //   eed_366d == 0 ? "" : eed_366d.toFixed(4).toString(),
                    //   mos_1d_na == "" ? (mos_1d == 0 ? "" : mos_1d.toFixed(4).toString()) : mos_1d_na,
                    //   mos_30d_na == "" ? (mos_30d == 0 ? "" : mos_30d.toFixed(4).toString()) : mos_30d_na,
                    //   mos_365d_na == "" ? (mos_365d == 0 ? "" : mos_365d.toFixed(4).toString()) : mos_365d_na,
                    //   mos_366d_na == "" ? (mos_366d == 0 ? "" : mos_366d.toFixed(4).toString()) : mos_366d_na,
                    //   "",
                    //   "",
                    //   "",
                    // ]);
                  }
                }

                j++;
                step2_data = lines[j].split(",");
              }
              

              //EXPORT INTO DATABASE FRIENDLY VERSION
              

              // if (apply_tsl == "Yes"){
              //   tsl_data.push(
              //     [""],
              //     [""],
              //     ["DATA FORMATTED TO IMPORT INTO DATABASE"],
              //     [""],
              //     [""],
              //     ["", "SUBMISSION INFO", "", "", "", "", "", "STEP 1: ENTER DEVICE INFO", "", "", "", "", "", "", "", "", "", "", "Chemical Exposure Data", "STEP 2: ENTER TQ DATA", "", "", "", "TSL<30d", "", "", "TSL>30d", "", "", "Toxicity Info", "", "", "", "Calculated EEDmax Values (mcg/kg/d)", "", "", "", "Calculated MOS Values", "", "", ""],
              //     ["CSV Filename", "Submission Number", "Sponsor Name", "Device Name", "Submitter Name", "Report Identifier", "Test Article Identifier", "What is the Device Contact Category?", "What is the Device Duration Category?", "What is the Device Contact Type?", "Is Device Repeatedly Used?", "Does Cumulative Exposure Apply?", "Is Duration Category (Q2) the Cumulative Exposure Duration?", "What is the Cumulative Exposure Duration?", "What is the Exposure Data Source?", "What is the Scaling Factor?", "What is the Population Group?", "Apply TSL?", "CASRN or InChlKey", "TQexposure (mcg)", "Cohort of Concern & Excluded Compounds?", "Genotoxic / Carcinogenic?", "Chemical PoD Available?", "TSL<30d Value", "TSL<30d : TQ Ratio", "Does TQ Exceed TSL<30d?", "TSL>30d Value", "TSL>30d : TQ Ratio", "Does TQ Exceed TSL>30d?", "TI<1d", "TI2-30d", "TI31-365d", "TI>366d", "EED<1d", "EED2-<30d", "EED31-<365d", "EED>366d", "MOS<1d", "MOS>2-<30d", "MOS>31-<365d", "MOS>366d"]
                   
              //   )
              //   for (let i = 0; i < database_results.length; i++) {
              //     tsl_data.push([(uploadname + "_Results_" + full_date), submission_number, sponsor_name, device_name, submitter_name, report_identifier, test_article_identifier, contact_category, duration_category, contact_type, single_use, cumulative_exposure, category_and_exposure, exposure_duration, data_source, scaling_factor, population_group, apply_tsl].concat(database_results[i]))
              //   }
              // }
              // else{
              //   results_data.push(
              //     [""],
              //     [""],
              //     ["DATA FORMATTED TO IMPORT INTO DATABASE"],
              //     [""],
              //     [""],
              //     ["", "SUBMISSION INFO", "", "", "", "", "", "STEP 1: ENTER DEVICE INFO", "", "", "", "", "", "", "", "", "", "", "Chemical Exposure Data", "STEP 2: ENTER TQ DATA", "", "", "", "", "TSL<30d", "", "", "TSL>30d", "", "", "Toxicity Info", "", "", "Calculated EEDmax Values (mcg/kg/d)", "", "", "Calculated MOS Values", "", "", "" ],
              //     ["CSV Filename", "Submission Number", "Sponsor Name", "Device Name", "Submitter Name", "Report Identifier", "Test Article Identifier", "What is the Device Contact Category?", "What is the Device Duration Category?", "What is the Device Contact Type?", "Is Device Repeatedly Used?", "Does Cumulative Exposure Apply?", "Is Duration Category (Q2) the Cumulative Exposure Duration?", "What is the Cumulative Exposure Duration?", "What is the Exposure Data Source?", "What is the Scaling Factor?", "What is the Population Group?", "Apply TSL?", "CASRN or InChlKey", "TQexposure (mcg)", "Cohort of Concern & Excluded Compounds?", "Genotoxic / Carcinogenic?", "Chemical PoD Available?", "TSL<30d Value", "TSL<30d : TQ Ratio", "Does TQ Exceed TSL<30d?", "TSL>30d Value", "TSL>30d : TQ Ratio", "Does TQ Exceed TSL>30d?", "TI<1d", "TI2-30d", "TI31-365d", "TI>366d", "EED<1d", "EED2-<30d", "EED31-<365d", "EED>366d", "MOS<1d", "MOS>2-<30d", "MOS>31-<365d", "MOS>366d"]
              //   )
              //   for (let i = 0; i < database_results.length; i++) {
              //     results_data.push([(uploadname + "_Results_" + full_date), submission_number, sponsor_name, device_name, submitter_name, report_identifier, test_article_identifier, contact_category, duration_category, contact_type, single_use, cumulative_exposure, category_and_exposure, exposure_duration, data_source, scaling_factor, population_group, apply_tsl].concat(database_results[i]))
              //   }

              // }
            
            }
          }
        }


        /* Append the date to the result csv file name (NOTE: for some reason, javascript offsets the months once in a while, so double check this every so often) */
        if (verification_pass) {
          var current = new Date();
          var full_date = current.getMonth() + 1 + "-" + current.getDate() + "-" + current.getFullYear() + ".csv";

          if (apply_tsl == "Yes") {
            exportToCsv(uploadname + "_Results_" + full_date, tsl_data);
          } else {
            exportToCsv(uploadname + "_Results_" + full_date, results_data);
          }
        }
      };
      reader.readAsBinaryString(file);
    } else {
      /* Invalid file uploaded */
      alert("The uploaded file must have the .xlsx file extension.");
      console.log("The uploaded file must have the .xlsx file extension.");
    }
  };

  /* HTML portion of the WebApp */
  return (
    <div className="App">
      <div className="App-Content">
        <h1>TSL_EED_MOS (TEEM) Calculator WebApp</h1>
        <h4>
          (<i>JavaScript must be activated in your web browser to generate a .csv file</i>)
        </h4>
        {/* Comment/uncomment the below h4 block when the version number is x.5 */}
        {/*<h4 style={{ color: "red" }}>Version 0.5 Notice: The embedded JavaScript code in this site is beta software and not for general use. This site is for testing purposes only.</h4>
        */}
        <br />

        <div className="Step">
          <h3>Step 1: Download and read the Instructions for Use.</h3>
          <button className="button">
            <a href={instructions} className="Download-Content" download="TEEM_Calculator_Instructions_For_Use">
              Download the Instructions for Use
            </a>
          </button>
          <br />
          <br />
        </div>

        <div className="Step">
          <h3>Step 2: Download a TEEM Calculator Smart-Template Spreadsheet (v3.9).</h3>
          <button className="button">
            <a href={template} className="Download-Content" download="TEEM_Calculator_Smart-Template_Spreadsheet">
              Download a TEEM Calculator Smart-Template Spreadsheet
            </a>
          </button>
          <br />
          <br />
          Note: If you are unable to open or use the Teem Calculator Smart-Template Spreadsheet, consider
          verifying the downloaded file is not corrupted by following these <a href = {directions} class="link" 
          download="Verify_TEEM_Calculator_Smart_Template">DIRECTIONS</a>.
          <br />
          <br />
          Note: Downloading and viewing TEEM Calculator Smart-Template Spreadsheet <a href = {examples} class="link" 
          download="TEEM_Calculator_Smart_Template_EXAMPLES_R1_FINAL">EXAMPLE INPUTS</a> may help avoid 
          errors when entering information into the template.
          <br />
          <br />

          {/* The team wanted to add some more security to the WebApp with an optional MDsum the user can complete, but it's not officially on the WebApp UI yet */}
          {/* <br />
          <br />
          <br />
          <h3>(Optional: Verify the integrity of the Smart-Template Spreadsheet with the following steps.)</h3>
          <h4>
            1. Open your computer's <strong>Powershell</strong> application.
          </h4>
          <h4>
            2. Type in{" "}
            <strong>
              <p style={{ fontFamily: "Courier New" }}>cd Downloads</p>
            </strong>{" "}
            and press enter.
          </h4>
          <h4>
            3. Type in{" "}
            <strong>
              <p style={{ fontFamily: "Courier New" }}>certutil -hashfile TEEM_Calculator_Smart-Template_Spreadsheet.xlsx MD5</p>
            </strong>{" "}
            and press enter.
          </h4>
          <h4>
            4. Make sure your MD5 sequence matches with: <strong>f2535a9fd01ac2c43efd17225527320b</strong>.
          </h4>
          <h4>5. If the MD5 sequences match, feel free to rename the file as you need, and continue below. Otherwise, please contact us and do not continue.</h4>
  <br />*/}
        </div>

        <div className="Step">
          <h3>Step 3: Enter and save your data into the TEEM Calculator Smart-Template Spreadsheet.</h3>
          <p>
          The content of the Smart-Template file IS NOT UPLOADED AND STORED server side. This tool does not retain any record of the inputs that will be used for calculations. 
          </p>
        </div>
          
        <div className="Step">
          <h3>Step 4: Click the button below and select your completed TEEM Calculator Smart-Template Spreadsheet.</h3>

          <p className="step4-attest">
            By initiating a completed TEEM Calculator template, you acknowledge that the TEEM Calculator output file (.csv) IS NOT a chemical toxicological risk assessment and the information entered in the TEEM Calculator Smart-Template is relevant to the medical device intended use and verifiable
            based on documented evidence that was obtained and used in accordance with the requirements, recommendations and methods of applicable medical device specific regulations, guidance and standards. To the best of your ability, you certify that the inputs entered into the Smart-Template
            file is sourced from the toxicological risk assessment report(s). You further certify that the toxicological risk assessment report includes the information sources and applicable justifications that were used in the selection or derivation of the inputs, and subsequent determinations of
            chemical and harm specific toxicological risk estimates for each extractable. You further certify that this completed TEEM Calculator Smart-Template, the output (.csv) file and the corresponding toxicological risk assessment report(s) will be provided to the regulatory authority at or
            during submission review.
          </p>
          <br />

          <input type="file" className="Download-Content" onChange={onChange} />
          <br />
          <p>
          Reminder! Check that the results (.csv) file is saved to your computer.<br />The results (.csv) file is not stored server side! 
          </p>
        </div>

        <div className="Step">
          <h3>Step 5: Save the results .csv file to your computer.</h3>
          <p className="step5-note">Your browser may either prompt you to save the file or it may automatically save to your Downloads folder. If you have not been prompted for 5 seconds, please check your Downloads folder.</p>

           {/* <button type="button" className="Download-Button">Trial Download Now</button> */}
          
          <br />
        </div>
      </div>

      <div className="Footer">
        <br />
        <p>
          <strong>Version 1.1 (8 April 2024)</strong>
        </p>
        <p>Report bugs, glitches, and new feature requests to Alan Hood, <a href="mailto:Alan.Hood@fda.hhs.gov">Alan.Hood@fda.hhs.gov</a> or Hainsworth Shin, <a href="mailto:Hainsworth.Shin@fda.hhs.gov">Hainsworth.Shin@fda.hhs.gov.</a>.
        </p>
        {/* Version 0.5: <p>Copyright © 2023 Jennifer Li, Hainsworth Shin, Caroline Pinto, Alan Hood</p>*/}
        <p>
          This JavaScript program is free software: you can redistribute it and/or modify it under the terms of the GNU Affero General Public License as published by the Free Software Foundation, version 3 of the License. This JavaScript program is distributed WITHOUT ANY WARRANTY; without even the
          implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more details at https://www.gnu.org/licenses/agpl-3.0.html. The TSL_EED_MOS (TEEM) Calculator WebApp JavaScript code cannot be included in closed source software under
          this license.
        </p>
      </div>
    </div>
  );
}

export default App;
